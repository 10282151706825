import axios from "axios";

export interface Novel {
    'name': string;
    'desc': string;
    'category': string;
}

export interface CatelogData {
    title: string;
    sequence: number;
    isVip: boolean;
}

export interface Chapter {
    'novel': Novel;
    'title': string;
    'content': string[];
    'hasNextChapter': boolean;
    'hasPrevChapter': boolean;
    'catelog': CatelogData[];
}

interface QueryParam {
    novelId: number;
    chapterId: number;
}

const getOne = async ({novelId, chapterId}: QueryParam) => {
    const response = await axios.get<Chapter>(`/v1/novels/${novelId}/chapters/${chapterId}`);
    return response.data;
};

const chapterService = {
    getOne,
};

export default chapterService;