import React, { useRef, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { SearchBar, InfiniteScroll, Empty } from "antd-mobile";
import { RedoOutline, DeleteOutline } from 'antd-mobile-icons';
import { SearchBarRef } from 'antd-mobile/es/components/search-bar';

import searchService, { RecommendData, SearchHot, BookItem } from "../services/search";
import { useQuery } from "react-query";

import './index.css';

const Search = () => {
    const navigate = useNavigate();
    const searchInput = useRef<SearchBarRef>(null);
    const [page, setPage] = useState<number>(1);
    const [searchKey, setSearchKey] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [searchResult, setSearchResult] = useState<BookItem[]>([]);
    const [searchHot, setSearchHot] = useState<SearchHot[]>([]);
    const [recommendBook, setRecommendBook] = useState<BookItem[]>([]);
    const [searchHistory, setSearchHistory] = useState<string[]>([]);
    useQuery<RecommendData, Error>('searchRecommendData', searchService.getRecommendData, {
        onSuccess: data => {
            setSearchHot(data.searchHot);
            setRecommendBook(data.recommend);
        }
    });

    useLayoutEffect(() => {
        if (searchInput.current) {
            searchInput.current.focus();
        }
        console.log('layout effect!');

        const searchHistoryJson: string | null = window.localStorage.getItem('searchHistory');
        if( searchHistoryJson !== null ) {
            const searchHistoryArray = JSON.parse(searchHistoryJson) as string[];
            setSearchHistory(searchHistoryArray);
        }
    }, []);

    const loadMore = async () => {
        setPage(v => v + 1);
        const append: BookItem[] = await searchService.getSearchResult(searchKey, page);
        setSearchResult(val => [...val, ...append]);
        setHasMore(append.length > 0);
    };

    const handleClear = (): void => {
        console.log('handleClear');
        setPage(1);
        setSearchKey('');
        setSearchResult([]);
        setHasMore(true);
    };

    const handleSearch = (value: string): void => {
        setPage(1);
        setSearchKey(value);
        setSearchResult([]);
        setHasMore(true);
        void loadMore();

        const searchHistoryJson: string | null = window.localStorage.getItem('searchHistory');
        if( searchHistoryJson === null ) {
            window.localStorage.setItem('searchHistory',JSON.stringify([value]));
        } else {
            const searchHistoryArray = JSON.parse(searchHistoryJson) as string[];
            window.localStorage.setItem('searchHistory', JSON.stringify([...searchHistoryArray, value]));
        }
    };

    const refreshSearchHot = (): void => {
        setSearchHot(searchHot.sort(() => Math.random() - 0.5).slice());
    };

    const refreshBooklist = (): void => {
        setRecommendBook(recommendBook.sort(() => Math.random() - 0.5).slice());
    };

    const clearSearchHistory = () => {
        setSearchHistory([]);
        window.localStorage.removeItem('searchHistory');
    };

    return (
        <>
            <div className="flex p-4 items-center justify-between">
                <div className="w-10/12">
                    <SearchBar onSearch={handleSearch} placeholder='搜索' onClear={handleClear} ref={searchInput} style={{ "--border-radius": "9999px" }} />
                </div>
                <div className="w-2/12 text-center text-gray-500" onClick={() => { navigate(-1); }}>
                    <span className="text-base">取消</span>
                </div>
            </div>
            {searchKey.length === 0 &&
                <>
                    {searchHistory.length > 0 &&
                        <div className="px-4 mb-4">
                            <div className="flex justify-between items-center">
                                <div className="text-base font-medium">搜索历史</div>
                                <DeleteOutline fontSize={16} onClick={clearSearchHistory} />
                            </div>
                            <ul className="flex flex-wrap mt-1">
                                {searchHistory.map((n, i) =>
                                    <li key={i} className="m-1 rounded-full bg-orange-100 py-1 px-4 text-gray-600">
                                        {n}
                                    </li>
                                )}
                            </ul>
                        </div>
                    }
                    <div className="px-4">
                        <div className="flex justify-between items-center">
                            <div className="text-base font-medium">热门搜索</div>
                            <RedoOutline fontSize={16} onClick={refreshSearchHot} />
                        </div>
                        <ul className="flex flex-wrap mt-1">
                            {searchHot.slice(0, 8).map((n) =>
                                <li key={n.id} className="m-1 rounded-full bg-orange-100 py-1 px-4 text-gray-600">{n.name}</li>
                            )}
                        </ul>
                    </div>
                </>
            }

            {searchKey.length > 0 &&
                <>
                    {searchResult.length > 0 ?
                        <div className="px-4">
                            <ul className="flex flex-wrap">
                                {searchResult.map(n =>
                                    <li className="flex my-2" key={n.id} onClick={() => { navigate(`/novel/${n.id}/chapter/1`); }}>
                                        <div className="w-3/12"><img src={n.cover} className="w-full rounded-lg" /></div>
                                        <div className="w-9/12 ml-2 flex flex-col justify-between">
                                            <div className="text-sm text-slate-800 font-medium">{n.name}</div>
                                            <p className="line-clamp-3 text-sm text-gray-400">{n.desc}</p>
                                            <div className="text-sm text-gray-400">
                                                <span>连载中</span>
                                                <span className="ml-4">玄幻</span>
                                            </div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
                        </div>
                        :
                        <Empty
                            style={{ padding: '64px 0' }}
                            imageStyle={{ width: 128 }}
                            description='暂无数据'
                        />
                    }
                </>
            }

            {(searchResult.length === 0 || searchKey.length === 0) &&
                <>
                    <div className="p-4">
                        <div className="flex justify-between items-center">
                            <div className="text-lg">
                                精彩推荐
                            </div>
                            <div>
                                <RedoOutline fontSize={16} onClick={refreshBooklist} />
                            </div>
                        </div>
                        <ul className="flex flex-wrap">
                            {recommendBook.slice(0, 4).map(n =>
                                <li className="flex my-2" key={n.id} onClick={() => { navigate(`/novel/${n.id}/chapter/1`); }}>
                                    <div className="w-3/12"><img src={n.cover} className="w-full rounded-lg" /></div>
                                    <div className="w-9/12 ml-2 flex flex-col justify-between">
                                        <div className="text-sm text-slate-800 font-medium">{n.name}</div>
                                        <p className="line-clamp-3 text-sm text-gray-400">{n.desc}</p>
                                        <div className="text-sm text-gray-400">
                                            <span>连载中</span>
                                            <span className="ml-4">玄幻</span>
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </>
            }
        </>
    );
};

export default Search;