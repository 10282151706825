import React, { useState } from "react";
import { NavBar } from "antd-mobile";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";

import './index.css';
import { useQuery } from "react-query";
import chapterService from "../services/chapter";
import Catelog from "./Catelog";

const Chapter = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    let novelId = 0;
    if (params.novelId) {
        novelId = parseInt(params.novelId, 10);
    }

    let chapterId = 0;
    if (params.chapterId) {
        chapterId = parseInt(params.chapterId, 10);
    }

    const { data } = useQuery(['chapter', novelId, chapterId], () => chapterService.getOne({ novelId, chapterId }));

    console.log(data);
    const back = () => {
        if (location.key === 'default') {
            navigate("/");
        } else {
            navigate(-1);
        }
    };

    console.log(novelId, chapterId, location.key);

    const [showSetting, setShowSetting] = useState(false);
    const [catelogVisible, setCatelogVisible] = useState(false);
    const [textSetting, setTextSetting] = useState(2);
    const [colorSetting, setColorSetting] = useState(0);

    const topClass = showSetting ? 'transition-transform translate-y-0' : 'transition-transform -translate-y-full';
    const bottomClass = showSetting ? 'transition-transform translate-y-0' : 'transition-transform	translate-y-full';
    const leftClass = catelogVisible ? 'translate-x-0' : '-translate-x-full';

    const handleFontsizeChange = (action: string) => {
        let index = textSetting;
        switch (action) {
            case "increment":
                index++;
                if (index >= textSettings.length) {
                    index = textSettings.length - 1;
                }
                setTextSetting(index);
                break;
            case "decrement":
                index--;
                if (index < 0) {
                    index = 0;
                }
                setTextSetting(index);
                break;
        }
    };

    const textSettings = [
        {
            "size": "text-xs",
            "indent": "indent-6",
        },
        {
            "size": "text-sm",
            "indent": "indent-7",
        },
        {
            "size": "text-base",
            "indent": "indent-8",
        },
        {
            "size": "text-lg",
            "indent": "indent-9",
        },
        {
            "size": "text-xl",
            "indent": "indent-10",
        },
        {
            "size": "text-2xl",
            "indent": "indent-12",
        }
    ];

    const showCatelog = () => {
        console.log("show category");
        setShowSetting(false);
        setCatelogVisible(true);
    };

    const handlePrevChapter = () => {
        setShowSetting(false);
        navigate(`/novel/${novelId}/chapter/${chapterId - 1}`);
    };

    const handleNextChapter = () => {
        setShowSetting(false);
        navigate(`/novel/${novelId}/chapter/${chapterId + 1}`);
    };

    return (
        <>
            <div className={`chapter-bg-${colorSetting} chapter-text-${colorSetting}`}>
                <div className="p-4">
                    <div>
                        <span className="text-lg font-medium">第{chapterId}章 {data?.title}</span>
                    </div>
                    <div className={`${textSettings[textSetting].size} select-none`} onClick={() => { setShowSetting(!showSetting); }}>
                        {data?.content.map((v, i) =>
                            <p key={i} className={`my-4 ${textSettings[textSetting].indent} break-all`}>{v}</p>
                        )}
                    </div>
                    <div className="grid grid-cols-3 gap-3 text-center text-sm text-gray-500 mt-10">
                        {data?.hasPrevChapter
                            ?
                            <div className="border border-gray-300 py-2 rounded" onClick={handlePrevChapter}>
                                <span>上一章</span>
                            </div>
                            :
                            <div className="border border-gray-200 py-2 rounded">
                                <span className="text-gray-200">上一章</span>
                            </div>
                        }
                        <div className="border border-gray-300 py-2 rounded" onClick={showCatelog}>
                            <span>目录</span>
                        </div>
                        {data?.hasNextChapter
                            ?
                            <div className="border py-2 rounded bg-orange-500 text-white" onClick={handleNextChapter}>
                                <span>下一章</span>
                            </div>
                            :
                            <div className="border border-gray-200 py-2 rounded">
                                <span className="text-gray-200">下一章</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={`${topClass} duration-300 ease-linear top-0 fixed w-full bg-white`}>
                <NavBar onBack={back}>{data?.novel.name}</NavBar>
            </div>
            <div className={`${bottomClass} rounded-t-2xl duration-300 ease-linear bottom-0 fixed w-full bg-white`}>
                <div className="grid grid-cols-4 gap-3 text-center text-sm text-gray-500 p-4 border-b border-slate-100">
                    {data?.hasPrevChapter
                        ?
                        <div className="py-2 rounded bg-gray-100" onClick={handlePrevChapter}>
                            <span>上一章</span>
                        </div>
                        :
                        <div className="py-2 rounded bg-gray-100">
                            <span className="text-gray-300">上一章</span>
                        </div>
                    }
                    <div className="py-2 rounded col-span-2 bg-gray-100" onClick={() => { showCatelog(); }}>
                        <span>连载中</span>
                        <span>{' '}·{' '}</span>
                        <span>目录</span>
                    </div>
                    {data?.hasNextChapter
                        ?
                        <div className="py-2 rounded bg-gray-100" onClick={handleNextChapter}>
                            <span>下一章</span>
                        </div>
                        :
                        <div className="py-2 rounded bg-gray-100">
                            <span className="text-gray-300">下一章</span>
                        </div>
                    }
                </div>
                <div className="grid grid-cols-6 gap-3 text-center text-sm text-gray-500 p-4">
                    {[0, 1, 2, 3].map(n =>
                        <div key={n} className={`rounded-md chapter-bg-${n} ${colorSetting === n ? `chapter-border-${n} border-2` : ''}`} onClick={() => { setColorSetting(n); }}></div>
                    )}
                    <div className="py-2 rounded-md bg-gray-100" onClick={() => { handleFontsizeChange('decrement'); }}>
                        <span className="text-xs">小</span>
                    </div>
                    <div className="py-1 rounded-md bg-gray-100" onClick={() => { handleFontsizeChange('increment'); }}>
                        <span className="text-lg">大</span>
                    </div>
                </div>
                <div className="grid grid-cols-4 gap-3 text-center text-sm text-gray-500 px-4 pb-4">
                    <div className="py-2 rounded bg-gray-100" onClick={() => { navigate('/'); }}>
                        <span>书城</span>
                    </div>
                    <Link to='/recharge/'>
                        <div className="py-2 rounded bg-gray-100 text-orange-500">
                            <span>充值</span>
                        </div>
                    </Link>
                    <div className="py-2 rounded bg-gray-100">
                        <span>收藏</span>
                    </div>
                    <div className="py-2 rounded bg-orange-100">
                        <span>福利</span>
                    </div>
                </div>
            </div>
            <div className={`${leftClass} transition-transform duration-300 ease-linear top-0 left-0 h-full fixed w-full z-10 flex`}>
                {data && 
                    <Catelog catelog={data.catelog} novel={data.novel} setCatelogVisible={setCatelogVisible}/>
                }
                <div className="w-2/12" onClick={() => { setCatelogVisible(false); }}></div>
            </div>
        </>
    );
};

export default Chapter;