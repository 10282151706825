import React from "react";
import { useInfiniteQuery } from "react-query";
import { InfiniteScroll } from "antd-mobile";
import { Link } from "react-router-dom";
import favoriteService, { FavoriteData } from "../services/favorite";

const FavoriteList = () => {

    const { data,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery<FavoriteData, Error>(['favorites'],
        ({ pageParam = 0 }: { pageParam?: number }) => favoriteService.getData({ pageParam }),
        {
            getNextPageParam: (lastPage) => lastPage.nextCursor,
        });

    const loadMore = async () => {
        await fetchNextPage();
    };

    return (
        <div className="m-4">
            <div className="text-sm">共{' '}<span>{data?.pages[0].total}</span>{' '}本书</div>
            <ul className="flex flex-wrap">
                {data?.pages.map((group, i) => (
                    <React.Fragment key={i}>
                        {group.list.map(n =>
                            <Link to={`/novel/${n.id}/chapter/${n.readAt}`} key={n.id} className="w-1/3 py-2 px-2.5">
                                <li>
                                    <div><img className="w-full rounded-lg" src={n.cover} /></div>
                                    <div className="text-sm font-medium text-gray-900">{n.name}</div>
                                    <div className="text-xs text-gray-500 mt-1">更新至 第{n.latest}章</div>
                                </li>
                            </Link>
                        )}
                    </React.Fragment>
                ))}
            </ul>
            <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage as boolean} />
        </div>
    );
};

export default FavoriteList;