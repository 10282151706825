import axios from "axios";

export interface SearchHot {
    id: number;
    name: string;
}

export interface BookItem {
    id: number;
    name: string;
    cover: string;
    desc: string;
    isFinish: number;
    category: string;
}

export interface RecommendData {
    searchHot: SearchHot[];
    recommend: BookItem[];
}

const getSearchResult = async (searchKey:string, page:number) => {
    const response = await axios.get<BookItem[]>(`/v1/search?key=${searchKey}&page=${page}`);
    return response.data;
};

const getRecommendData = async () => {
    const response = await axios.get<RecommendData>('/v1/search/recommend');
    return response.data;
};

const searchService = {
    getSearchResult,
    getRecommendData
};

export default searchService;