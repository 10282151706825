import axios from "../config/axios";

interface Swiper {
    id: number;
    color: string;
}

interface bookItem {
    id: number;
    name: string;
    cover: string;
    desc?: string;
}

export interface HomeData {
    swiper: Swiper[];
    hot: bookItem[];
    recommentTop: bookItem[];
    recommend: bookItem[];
    choice: bookItem[];
}

const getData = async () => {
    const response = await axios.get<HomeData>('/v1/home');
    return response.data;
};

const homeService = {
    getData
};

export default homeService;