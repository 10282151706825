import axios from "axios";

export interface BookItem {
    id: number;
    name: string;
    cover: string;
    desc: string;
    isFinish: number;
    category: string;
}

export interface CategoryData {
    list: BookItem[];
    nextCursor: number;
}

interface QueryParam {
    pageParam: number;
    channel: number;
    category: number;
    isFinish: number;
}

const getData = async ({pageParam, channel, category, isFinish}: QueryParam) => {
    const response = await axios.get<CategoryData>(`/v1/libraries?page=${pageParam}&channel=${channel}&category=${category}&isFinish=${isFinish}`);
    return response.data;
};

const librariesService = {
    getData,
};

export default librariesService;