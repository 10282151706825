import React from "react";

import { useQuery } from "react-query";
import { SearchBar, Swiper } from 'antd-mobile';
import { UserOutline, ClockCircleOutline } from 'antd-mobile-icons';
import { AiOutlineCrown } from 'react-icons/ai';
import { BiCategory } from 'react-icons/bi';

import { useNavigate, Link } from "react-router-dom";

import "./index.css";
import homeService, { HomeData } from "../services/home";

function Home() {
    const navigate = useNavigate();
    const {data} = useQuery<HomeData, Error>('homeData', homeService.getData);
    const items = data && data.swiper.map((item, index) => (
        <Swiper.Item key={index}>
            <div
                className="h-28 rounded-xl"
                style={{ "background": item.color }}
                onClick={() => {
                    navigate(`/novel/${item.id}/chapter/1`);
                }}
            >
            </div>
        </Swiper.Item>
    ));

    const iconSize = 32;

    return (
        <div className="p-4">
            <div>
                <SearchBar placeholder='搜索' onFocus={() => { navigate("/search"); }} style={{ "--border-radius": "9999px" }} />
            </div>
            <div className="my-4">
                <Swiper autoplay={true}>{items}</Swiper>
            </div>
            <div className="flex justify-between m-4">
                <Link to='/libaray'>
                    <div className="flex flex-col items-center">
                        <BiCategory fontSize={iconSize} color={'rgb(107 114 128)'} />
                        <div className="text-slate-800 text-sm mt-1">分类</div>
                    </div>
                </Link>
                <Link to='/ranklist'>
                    <div className="flex flex-col items-center">
                        <AiOutlineCrown fontSize={iconSize} color={'rgb(107 114 128)'} />
                        <div className="text-slate-800 text-sm mt-1">榜单</div>
                    </div>
                </Link>
                <Link to='/history' state={{ referer: 'history' }}>
                    <div className="flex flex-col items-center">
                        <ClockCircleOutline fontSize={iconSize} color={'rgb(107 114 128)'} />
                        <div className="text-slate-800 text-sm mt-1">最近阅读</div>
                    </div>
                </Link>
                <Link to='/user'>
                    <div className="flex flex-col items-center">
                        <UserOutline fontSize={iconSize} color={'rgb(107 114 128)'} />
                        <div className="text-slate-800 text-sm mt-1">我的</div>
                    </div>
                </Link>
            </div>
            <div className="mt-2">
                <div className="flex justify-between items-center">
                    <div className="text-base font-medium">
                        热门推荐
                    </div>
                    <div className="text-gray-400">
                        更多
                    </div>
                </div>
                <ul className="flex flex-wrap">
                    {data?.hot.map(n =>
                        <li key={n.id} className="w-1/3 p-2">
                            <Link to={`/novel/${n.id}/chapter/1`}>
                                <div><img src={n.cover} className="w-full rounded-lg" /></div>
                                <div className="text-sm text-slate-800 mt-1 font-medium">{n.name}</div>
                            </Link>
                        </li>
                    )}
                </ul>
            </div>

            <div className="mt-2">
                <div className="flex justify-between items-center">
                    <div className="text-base font-medium">
                        主编力荐
                    </div>
                    <div className="text-gray-400">
                        更多
                    </div>
                </div>
                <ul className="flex flex-wrap">
                    {data && data.recommentTop.map(n =>
                        <Link key={n.id} to={`/novel/${n.id}/chapter/1`}>
                            <li className="flex">
                                <div className="w-1/3 p-2">
                                    <div><img src={n.cover} className="w-full rounded-lg" /></div>
                                </div>
                                <div className="w-2/3 py-2 flex flex-col justify-between">
                                    <div className="text-sm text-slate-800 font-medium">{n.name}</div>
                                    <p className="text-gray-500 line-clamp-3 text-sm">{n.desc}</p>
                                    <div className="text-gray-500 text-sm">
                                        <span>连载中</span>
                                        <span className="ml-4">玄幻</span>
                                    </div>
                                </div>
                            </li>
                        </Link>
                    )}
                    {data && data.recommend.map(n =>
                        <li key={n.id} className="w-1/3 p-2">
                            <Link to={`/novel/${n.id}/chapter/1`}>
                                <div><img src={n.cover} className="w-full rounded-lg" /></div>
                                <div className="text-sm text-slate-800 mt-1 font-medium">{n.name}</div>
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
            <div className="mt-2">
                <div className="flex justify-between items-center">
                    <div className="text-base font-medium">
                        精选
                    </div>
                    <div className="text-gray-400">
                        更多
                    </div>
                </div>
                <ul className="flex flex-wrap">
                    {data && data.choice.map((n, i) =>
                        <li className="flex my-2" key={i} onClick={() => { navigate('/novel/50032/chapter/1'); }}>
                            <div className="w-3/12"><img src={n.cover} className="w-full rounded-lg" /></div>
                            <div className="w-9/12 ml-2 flex flex-col justify-between">
                                <div className="text-sm text-slate-800 font-medium">{n.name}</div>
                                <p className="line-clamp-3 text-sm text-gray-400">{n.desc}</p>
                                <div className="text-sm text-gray-400">
                                    <span>连载中</span>
                                    <span className="ml-4">玄幻</span>
                                </div>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default Home;