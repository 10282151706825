import React, { } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { NavBar } from "antd-mobile";
import { useInfiniteQuery } from "react-query";
import consumeService, { ConsumeData } from "../services/consume";
import { InfiniteScroll } from "antd-mobile";

const Consume = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const back = () => navigate(-1);

    console.log(location);

    const { data,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery<ConsumeData, Error>(['consume'],
        ({ pageParam = 0 }: { pageParam?: number }) => consumeService.getAll({ pageParam }),
        {
            getNextPageParam: (lastPage) => lastPage.nextCursor,
        });

    const loadMore = async () => {
        await fetchNextPage();
    };

    return (
        <>
            <NavBar onBack={back} style={{ "--border-bottom": "1px solid #eeeeee" }}>消费记录</NavBar>
            <ul className="divide-y divide-slate-100">
                {data?.pages.map((group, i) => (
                    <React.Fragment key={i}>
                        {group.list.map(n =>
                            <li key={n.id} className="flex justify-between p-4 items-center">
                                <div>
                                    <div>{n.type}</div>
                                    <div className="text-gray-400 mt-1">{n.title}</div>
                                    <div className="text-gray-400 mt-1">{n.date}</div>
                                </div>
                                <div>
                                    <div className="text-sm text-right font-medium text-red-600">- {n.coin} 书币</div>
                                    <div className="text-sm text-right font-medium text-red-600">- {n.giftCoin} 书券</div>
                                </div>
                            </li>
                        )}
                    </React.Fragment>
                ))}
            </ul>
            <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage as boolean} />
        </>
    );
};

export default Consume;