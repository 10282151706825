import React from "react";
import { useNavigate } from "react-router-dom";
import { NavBar, Image } from "antd-mobile";

const Novel = () => {
    const navigate = useNavigate();

    const back = () => {
        navigate(-1);
    };

    return (
        <>
            <NavBar back='返回' onBack={back}></NavBar>
            <div className="flex flex-col items-center my-5">
                <div className="w-4/12">
                    <Image src={'http://123.56.96.254/covers/3q0Lf8mMEXYUDawFyu04utTaQlc9FMubRPGdWk9n.jpg'} width={"100%"} fit='fill' style={{ borderRadius: 5 }} />
                </div>
                <div>
                    我的武功暴打修仙者
                </div>
                <div>
                    永恒剑仙
                </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
                <div>
                    <div>在读</div>
                    <div>1000</div>
                </div>
                <div>
                    <div>在读</div>
                    <div>1000</div>
                </div>
                <div>
                    <div>在读</div>
                    <div>1000</div>
                </div>
            </div>
            <div>
                <div>简介</div>
                <p>穿越到仙武世界的李牧发现，自己居然有个系统，可以给自己的武功叠加各种被动BUFF。
                    “您的《抱山印》拥有：‘重如穹天LV99、无视防御LV99、迅疾LV99。’”
                    “您的《山海拳印》拥有：‘真龙之力LV99，烛龙之神LV99，凤凰之焰LV99……’
                    “您的《斗转星移》拥有：‘颠倒阴阳LV99，乾坤逆转LV99。’”
                    那一天，李牧一拳击出，天崩地裂，阴阳颠倒，时光倒流，焚天煮海，洪水漫天，飓风呼啸，飞沙走石，电闪雷鸣，枯木逢春……</p>
            </div>
            <div className="m-4">
                <button className="bg-rose-600 text-sm text-white px-6 py-2 rounded-full w-full" onClick={() => { navigate('/novel/50028/chapter/1'); }}>继续阅读</button>
            </div>
        </>
    );
};

export default Novel;