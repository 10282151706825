import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavBar } from "antd-mobile";

import wx, { ConfigOptions } from 'weixin-js-sdk';
import axios from "../config/axios";

interface WxConfig {
    appId: string;
    timeStamp: number;
    nonceStr: string;
    signature: string;
}

interface SignData {
    appId: string;
    timeStamp: string;
    nonceStr: string;
    package: string;
    signType: 'MD5';
    paySign: string;
}

const Recharge = () => {
    const navigate = useNavigate();
    const [money, setMoney] = useState(30);

    useEffect(() => {
        const setConfig = async () => {
            const { data: config } = await axios.get<WxConfig>('/v1/recharge');

            const options: ConfigOptions = {
                debug: false,
                ...config,
                jsApiList: ['chooseWXPay']
            };

            console.log(options);
            void wx.config(options);
        };

        void setConfig();
    }, []);

    const getSignData = async () => {
        const { data: signData } = await axios.post<SignData>('/v1/recharge', { money: money });
        console.log(signData);

        wx.chooseWXPay({
            timestamp: signData.timeStamp,
            nonceStr: signData.nonceStr,
            package: signData.package,
            signType: signData.signType,
            paySign: signData.paySign,
            success: function (res) {
                // 支付成功后的回调函数
                console.log(res, 'success');
            },
            fail: function (res) {
                console.log(res, 'fail');
            }
        });
    };

    const handleRecharge = () => {
        void getSignData();
    };

    const moneyConfig = [
        {
            "money": 30,
            "coin": 3000,
            "giftCoin": 1000
        },
        {
            "money": 50,
            "coin": 5000,
            "giftCoin": 3000
        },
        {
            "money": 70,
            "coin": 7000,
            "giftCoin": 5000
        },
        {
            "money": 90,
            "coin": 9000,
            "giftCoin": 7000
        },
        {
            "money": 100,
            "coin": 10000,
            "giftCoin": 8000
        },
        {
            "money": 499,
            "coin": 0,
            "giftCoin": 0,
            "isVip": true
        }
    ];

    return (
        <>
            <NavBar onBack={() => { navigate(-1); }} >充值</NavBar>
            <div className="flex divide-x divide-gray-100/50 bg-orange-500 mx-3 text-white p-4 rounded">
                <div className="w-6/12">
                    <div className="text-gray-100">我的书币</div>
                    <div className="text-lg font-medium">0</div>
                </div>
                <div className="w-6/12 pl-4">
                    <div className="text-gray-100">我的书券</div>
                    <div className="text-lg font-medium">0</div>
                </div>
            </div>
            <div className="m-4 text-base font-medium">
                请选择充值金额
            </div>
            <div className="grid grid-cols-2 gap-4 m-3">
                {moneyConfig.map((n, i) =>
                    <div className={`${n.money === money ? 'border-orange-400' : 'border-gray-50'} border-2 rounded py-5 px-1 text-center text-sm space-y-2 text-gray-500`} key={i} onClick={() => setMoney(n.money)}>
                        <div>充<span className="text-base mx-1 font-medium text-black">{n.money}</span>元</div>
                        {n.isVip ?
                            <div className="text-xs">VIP包年任意看</div>
                            :
                            <div className="text-xs">得<span className="mx-0.5 font-medium text-gray-600">{n.coin}</span>书币+送<span className="mx-0.5 font-medium text-gray-600">{n.giftCoin}</span>书券</div>
                        }
                    </div>
                )}
            </div>

            <div className="my-4 mx-2" onClick={handleRecharge}>
                <button className="bg-orange-500 text-sm text-white px-6 py-2 rounded-full w-full">充值{money}元</button>
            </div>
            <div className="m-3">
                <p>温馨提示：</p>
                <p>1. 充值获得的书币仅限在本公司书城使用。</p>
                <p>2. 若是发现充值未到账，请先确认你登录的账号与充值的账号是否一致。</p>
                <p>3. 充值赠送的书币存在有效期，有效期为：自领取时间起， 3天有效；消费时优先扣除赠送书币。</p>
                <p>4. 充值获得的书币或看点为虚拟货币，一般不可退换，但法律或协议另有规定的除外。</p>
                <p>5. 未满18周岁的未成年人应在法定监护人同意下进行充值。</p>
                <p>6. 书籍详情页有“非VIP书籍”标识的书籍不享受VIP免费看权益。</p>
                <p>7. 点击充值即表示您已阅读并同意《用户协议》、《VIP会员服务协议》 和《隐私策略》。</p>
            </div>
        </>
    );
};

export default Recharge;