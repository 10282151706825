import axios from "axios";

export interface BookItem {
    id: number;
    name: string;
    cover: string;
    desc: string;
    isFinish: number;
    category: string;
}

export interface RankList {
    list: BookItem[];
    nextCursor: number;
}

interface QueryParam {
    pageParam: number;
    channel: number;
    rank: number;
}

const getData = async ({pageParam, channel, rank}: QueryParam) => {
    const response = await axios.get<RankList>(`/v1/ranklist?page=${pageParam}&channel=${channel}&rank=${rank}`);
    return response.data;
};

const ranklistService = {
    getData,
};

export default ranklistService;