import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { NavBar } from "antd-mobile";
import { BiUserCircle } from 'react-icons/bi';
import { PayCircleOutline, RightOutline, ShopbagOutline, ClockCircleOutline, HeartOutline } from 'antd-mobile-icons';
import { useQuery } from "react-query";
import userService from "../services/user";

const User = () => {
    const navigate = useNavigate();

    const back = () => {
        navigate('/');
    };

    const { data } = useQuery('userinfo', userService.getData);

    console.log(data);
    return (
        <>
            <NavBar onBack={back} style={{ "--border-bottom": "1px solid #eeeeee" }}>我的</NavBar>
            <div className="flex flex-col items-center my-10">
                <BiUserCircle size={120} />
                <div className="mt-2 text-sm">
                    书友
                </div>
                <div className="mt-2 text-sm">
                    ID: {data?.id}
                </div>
                <div className="flex mt-4 text-sm text-center">
                    <div className="flex flex-col items-center">
                        <div className="text-base font-medium text-orange-400">{data?.coin}</div>
                        <div className="text-slate-400">书币</div>
                    </div>
                    <div className="flex flex-col item-center ml-20">
                        <div className="text-base font-medium text-orange-400">{data?.giftCoin}</div>
                        <div className="text-slate-400">书券</div>
                    </div>
                </div>
                <div className="mt-8">
                    <Link to="/recharge/">
                        <span className="bg-orange-400 text-sm text-white px-10 py-2 rounded-full">充值</span>
                    </Link>
                </div>
            </div>

            <div className="mx-4">
                <ul className="divide-y divide-slate-100">
                    <li className="flex items-center justify-between py-5" onClick={() => { navigate('/recharge/history'); }}>
                        <div className="flex items-center">
                            <PayCircleOutline fontSize={22} />
                            <span className="ml-4 text-sm">充值记录</span>
                        </div>
                        <RightOutline fontSize={18} />
                    </li>
                    <li className="flex items-center justify-between py-5" onClick={() => { navigate('/consume'); }}>
                        <div className="flex items-center">
                            <ShopbagOutline fontSize={22} />
                            <span className="ml-4 text-sm">消费记录</span>
                        </div>
                        <RightOutline fontSize={18} />
                    </li>
                    <li>
                        <Link to="/history" state={{ referer: 'history' }} className="flex items-center justify-between py-5">
                            <div className="flex items-center">
                                <ClockCircleOutline fontSize={22} color="#111827" />
                                <span className="ml-4 text-sm text-gray-900">最近阅读</span>
                            </div>
                            <RightOutline fontSize={18} color="#111827" />
                        </Link>
                    </li>
                    <li>
                        <Link to="/favorite" state={{ referer: 'favorite' }} className="flex items-center justify-between py-5">
                            <div className="flex items-center">
                                <HeartOutline fontSize={22} color="#111827" />
                                <span className="ml-4 text-sm text-gray-900">我的收藏</span>
                            </div>
                            <RightOutline fontSize={18} color="#111827" />
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default User;