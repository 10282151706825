import axios from "axios";

export interface BookItem {
    id: number;
    name: string;
    cover: string;
    latest: number;
    readAt: number;
}

export interface FavoriteData {
    list: BookItem[];
    nextCursor: number;
    total: number;
}

interface QueryParam {
    pageParam: number;
}

const getData = async ({pageParam}: QueryParam) => {
    const response = await axios.get<FavoriteData>(`/v1/favorite?page=${pageParam}`);
    return response.data;
};

const favoriteService = {
    getData,
};

export default favoriteService;