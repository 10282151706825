import React, { useState } from "react";

import { CatelogData, Novel } from "../services/chapter";
import { useNavigate } from "react-router-dom";

const Catelog = ({ catelog, novel, setCatelogVisible }: { catelog: CatelogData[], novel: Novel, setCatelogVisible(catelogVisible: boolean): void }) => {
    const [list, setList] = useState(catelog);
    const [showDesc, setShowDesc] = useState(false);
    const navigate = useNavigate();

    const descClass = showDesc ? '' : 'line-clamp-3';

    const sortCatelog = () => {
        setList(list.reverse().slice());
    };

    return (
        <div className="w-10/12 bg-white flex flex-col">
            <div className="shrink">
                <div className="px-4 py-2">
                    <div className="flex justify-between text-sm font-medium">
                        <div>
                            <span>{novel.name}</span>
                            <span>{' '}·{' '}</span>
                            <span>玄幻</span>
                        </div>
                        <div onClick={() => { setShowDesc(!showDesc); }}>
                            <span className="text-orange-500">简介</span>
                        </div>
                    </div>
                    <p className={`mt-1 text-sm ${descClass} text-gray-500`}>{novel.desc}</p>
                </div>
                <div className="py-2 px-4 border-y text-sm text-medium flex justify-between">
                    <span>共1022章</span>
                    <span onClick={() => { sortCatelog(); }}>正序</span>
                </div>
            </div>
            <ul className="overflow-y-auto pl-8 divide-y divide-slate-100 overscroll-none">
                {list.map(n =>
                    <li key={n.sequence} className="py-3 text-sm flex justify-between" onClick={() => { setCatelogVisible(false); navigate(`/novel/${1}/chapter/${n.sequence}`); }}>
                        <span>第{n.sequence}章 {n.title}</span>
                        {n.isVip &&
                            <span className="pr-4">VIP</span>
                        }
                    </li>
                )}
            </ul>
        </div>
    );
};

export default Catelog;