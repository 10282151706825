import React from 'react';
import { Routes, Route, Link, useLocation } from "react-router-dom";
import axios from './config/axios';

import Home from './home';
import Search from './search';
import Chapter from './chapter';
import Libaray from './libaray';
import Recharge from './recharge';
import RechargeHistory from './recharge/History';
import History from './history';
import User from './user';
import Ranklist from './ranklist';
import Consume from './consume';
import Novel from './novel';

const App = () => {
  const getUserToken = async (code:string) => {
    try {
      const {data: token} = await axios.post<[token:string]>('/v1/login', {code: code});
      window.localStorage.setItem('loggedUser', JSON.stringify(token));
    } catch(e) {
      console.log(e);
    }
  };

  const loggedUserJSON = window.localStorage.getItem('loggedUser');
  if( loggedUserJSON === null ) {
    const search = window.location.search;
    if( search ) {
      search.substring(1).split('&').map( n => {
            const key = n.split('=')[0];
            const value = n.split('=')[1];
            if( key === 'code' ) {
                void getUserToken(value);
            }
        });
    } else {
        const appId = process.env.REACT_APP_WXMP_APPID as string;
        const redirect_uri = window.location.href;
        const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
        if( process.env.NODE_ENV !== 'development' ) {
          window.location.href = url;
          return null;
        }
    }
  }

  const location = useLocation();
  React.useEffect(() => {
    console.log('location change!');
  }, [location]);

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="search" element={<Search />} />
      <Route path="novel/:novelId/chapter/:chapterId" element={<Chapter />} />
      <Route path="novel/:novelId" element={<Novel />} />
      <Route path="libaray" element={<Libaray />} />
      <Route path="recharge" element={<Recharge />} />
      <Route path="recharge/history" element={<RechargeHistory />} />
      <Route path="consume" element={<Consume />} />
      <Route path="history" element={<History />} />
      <Route path="favorite" element={<History />} />
      <Route path="user" element={<User />} />
      <Route path="ranklist" element={<Ranklist />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

const NoMatch = () => {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
};

export default App;