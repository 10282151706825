import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { NavBar, InfiniteScroll } from "antd-mobile";

import { useInfiniteQuery } from "react-query";
import librariesService, { CategoryData } from "../services/libaraies";

const Libaray = () => {
    const [channel, setChannel] = useState<number>(1);
    const [category, setCategory] = useState<number>(0);
    const [isFinish, setIsFinish] = useState<number>(0);

    const { data,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery<CategoryData, Error>(['libraries', channel, category, isFinish],
        ({pageParam = 0}:{pageParam?:number}) => librariesService.getData({ pageParam, channel, category, isFinish }),
        {
            getNextPageParam: (lastPage) => lastPage.nextCursor,
        });

    const navigate = useNavigate();
    const back = () => navigate("/");

    const categoryData = [{
        'channel': 1,
        'category': [{
            "id": 1,
            "name": "都市"
        }, {
            "id": 2,
            "name": "玄幻"
        }, {
            "id": 3,
            "name": "仙侠"
        }, {
            "id": 4,
            "name": "历史"
        }, {
            "id": 5,
            "name": "悬疑"
        }, {
            "id": 6,
            "name": "科幻"
        }, {
            "id": 7,
            "name": "游戏"
        }]
    }, {
        'channel': 2,
        'category': [{
            "id": 8,
            "name": "现代言情"
        }, {
            "id": 9,
            "name": "古代言情"
        }, {
            "id": 10,
            "name": "幻想言情"
        }, {
            "id": 11,
            "name": "悬疑推理"
        }]
    }];

    const loadMore = async () => {
        await fetchNextPage();
    };

    console.log(hasNextPage, data);
    return (
        <>
            <NavBar onBack={back} style={{ "--border-bottom": "1px solid #eeeeee" }}>分类</NavBar>
            <div className="p-3 border-b border-slate-100 text-sm grid grid-cols-4 gap-4">
                <div className={`${channel === 1 ? 'bg-orange-500 text-white' : 'bg-gray-100'} py-1 text-center rounded-full`} onClick={() => { setChannel(1); }}>男频</div>
                <div className={`${channel === 2 ? 'bg-orange-500 text-white' : 'bg-gray-100'} py-1 text-center rounded-full`} onClick={() => { setChannel(2); }}>女频</div>
            </div>
            <div className="p-3 border-b border-slate-100 text-sm grid grid-cols-4 gap-4">
                <div className={`${category === 0 ? 'bg-orange-500 text-white' : 'bg-gray-100'} py-1 text-center rounded-full`} onClick={() => { setCategory(0); }}>全部</div>
                {categoryData.filter(c => c.channel === channel)[0].category.map(n =>
                    <div key={n.id} className={`${category === n.id ? 'bg-orange-500 text-white' : 'bg-gray-100'} py-1 text-center rounded-full`} onClick={() => { setCategory(n.id); }}>{n.name}</div>
                )}
            </div>
            <div className="p-3 border-b border-slate-100 text-sm grid grid-cols-4 gap-4">
                <div className={`${isFinish === 0 ? 'bg-orange-500 text-white' : 'bg-gray-100'} py-1 text-center rounded-full`} onClick={() => { setIsFinish(0); }}>全部</div>
                <div className={`${isFinish === 1 ? 'bg-orange-500 text-white' : 'bg-gray-100'} py-1 text-center rounded-full`} onClick={() => { setIsFinish(1); }}>连载</div>
                <div className={`${isFinish === 2 ? 'bg-orange-500 text-white' : 'bg-gray-100'} py-1 text-center rounded-full`} onClick={() => { setIsFinish(2); }}>完本</div>
            </div>
            <div className="p-4">
                <ul className="flex flex-wrap">
                    {data?.pages.map((group, i) => (
                        <React.Fragment key={i}>
                            {group.list.map(n =>
                                <li className="flex my-2" key={n.id} onClick={() => { navigate(`/novel/${n.id}/chapter/1`); }}>
                                    <div className="w-3/12"><img src={n.cover} className="w-full rounded-lg" /></div>
                                    <div className="w-9/12 ml-2 flex flex-col justify-between">
                                        <div className="text-sm text-slate-800 font-medium">{n.name}</div>
                                        <p className="line-clamp-3 text-sm text-gray-400">{n.desc}</p>
                                        <div className="text-sm text-gray-400">
                                            <span>{n.isFinish ? '完结' : '连载'}</span>
                                            <span className="ml-4">{n.category}</span>
                                        </div>
                                    </div>
                                </li>
                            )}
                        </React.Fragment>
                    ))}
                </ul>
                <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage as boolean} />
            </div>
        </>
    );
};

export default Libaray;