import React from "react";
import { useInfiniteQuery } from "react-query";
import historyService, { HistoryData } from "../services/history";
import { InfiniteScroll } from "antd-mobile";
import { Link } from "react-router-dom";

const HistoryList = () => {

    const { data,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery<HistoryData, Error>(['histories'],
        ({ pageParam = 0 }: { pageParam?: number }) => historyService.getData({ pageParam }),
        {
            getNextPageParam: (lastPage) => lastPage.nextCursor,
        });

    const loadMore = async () => {
        await fetchNextPage();
    };

    return (
        <div className="m-4">
            <div className="text-sm">共{' '}<span>{data?.pages[0].total}</span>{' '}本书</div>
            <ul className="flex flex-wrap">
                {data?.pages.map((group, i) => (
                    <React.Fragment key={i}>
                        {group.list.map(n =>
                            <Link to={`/novel/${n.id}/chapter/${n.readAt}`} key={n.id}>
                            <li className="flex my-2">
                                <div className="w-3/12"><img className="w-full rounded-lg" src={n.cover} /></div>
                                <div className="w-9/12 ml-2 flex flex-col justify-between">
                                    <div className="text-sm font-medium text-gray-900">{n.name}</div>
                                    <div className="text-gray-500">更新至 第{n.latest}章</div>
                                    <div className="flex justify-between text-gray-500">
                                        <span>阅读到 第{n.readAt}章</span>
                                        <span>继续阅读</span>
                                    </div>
                                </div>
                            </li>
                            </Link>
                        )}
                    </React.Fragment>
                ))}
            </ul>
            <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage as boolean} />
        </div>
    );
};

export default HistoryList;