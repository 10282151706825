import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { NavBar, InfiniteScroll } from "antd-mobile";

import { useInfiniteQuery } from "react-query";
import ranklistService, { RankList } from "../services/ranklist";

const Ranklist = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const back = () => navigate("/");
    const [channel, setChannel] = useState<number>(1);
    const [rank, setRank] = useState<number>(1);

    console.log(location);

    const { data,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery<RankList, Error>(['libraries', channel, rank],
        ({ pageParam = 0 }: { pageParam?: number }) => ranklistService.getData({ pageParam, channel, rank }),
        {
            getNextPageParam: (lastPage) => lastPage.nextCursor,
        });

    const loadMore = async () => {
        await fetchNextPage();
    };

    return (
        <>
            <NavBar onBack={back} style={{ "--border-bottom": "1px solid #eeeeee" }}>榜单</NavBar>
            <div className="grid grid-cols-2 gap-4 border-b border-slate-100 text-center text-lg px-2">
                <div>
                    <span className={`pt-2.5 pb-2 inline-block ${channel === 1 ? 'text-orange-500 border-b-2 border-orange-500 ' : ''}`} onClick={() => { setChannel(1); }}>男频</span>
                </div>
                <div>
                    <span className={`pt-2.5 pb-2 inline-block ${channel === 2 ? 'text-orange-500 border-b-2 border-orange-500 ' : ''}`} onClick={() => { setChannel(2); }}>女频</span>
                </div>
            </div>
            <div className="flex">
                <div className="flex-none text-sm bg-slate-50">
                    <div onClick={() => { setRank(1); }}>
                        <span className={`px-4 py-3 inline-block border-l-2 ${rank === 1 ? 'text-orange-500 border-orange-500 bg-slate-100' : 'border-transparent'}`} onClick={() => { setRank(1); }}>点击榜</span>
                    </div>
                    <div onClick={() => { setRank(2); }}>
                        <span className={`px-4 py-3 inline-block border-l-2 ${rank === 2 ? 'text-orange-500 border-orange-500 bg-slate-100' : 'border-transparent'}`}>畅销榜</span>
                    </div>
                </div>
                <div className="flex flex-col">
                    <ul>
                        {data?.pages.map((group, i) => (
                            <React.Fragment key={i}>
                                {group.list.map(n =>
                                    <li className="flex m-2" key={n.id} onClick={() => { navigate(`/novel/${n.id}/chapter/1`); }}>
                                        <div className="w-3/12"><img src={n.cover} className="w-full rounded-lg" /></div>
                                        <div className="w-9/12 ml-2 flex flex-col justify-between">
                                            <div className="text-sm text-slate-800 font-medium">{n.name}</div>
                                            <p className="line-clamp-2 text-xs text-gray-400">{n.desc}</p>
                                            <div className="text-xs text-gray-400">
                                                <span>{n.isFinish ? '完结' : '连载'}</span>
                                                <span className="ml-4">{n.category}</span>
                                            </div>
                                        </div>
                                    </li>
                                )}
                            </React.Fragment>
                        ))}
                    </ul>
                    <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage as boolean} />
                </div>
            </div>
        </>
    );
};

export default Ranklist;