import axios from "axios";

interface userToken {
    token:string;
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const loggedUser = window.localStorage.getItem('loggedUser');
if( loggedUser !== null ) {
    const user = JSON.parse(loggedUser) as userToken;
    axios.defaults.headers.common['Authorization'] = user.token;
}

export default axios;