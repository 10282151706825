import axios from "axios";

export interface RechargeItem {
    id: number;
    type: number;
    money: number;
    coin: number;
    giftCoin: number;
    date: string;
    expireAt?: string;
}

export interface RechargeHistoryData {
    list: RechargeItem[];
    nextCursor: number;
}

interface QueryParam {
    pageParam: number;
}

const getHistory = async ({pageParam}: QueryParam) => {
    const response = await axios.get<RechargeHistoryData>(`/v1/recharge/history?page=${pageParam}`);
    return response.data;
};

const rechargeService = {
    getHistory,
};

export default rechargeService;