import axios from "axios";

export interface BookItem {
    id: number;
    name: string;
    cover: string;
    latest: number;
    readAt: number;
}

export interface HistoryData {
    list: BookItem[];
    nextCursor: number;
    total: number;
}

interface QueryParam {
    pageParam: number;
}

const getData = async ({pageParam}: QueryParam) => {
    const response = await axios.get<HistoryData>(`/v1/history?page=${pageParam}`);
    return response.data;
};

const historyService = {
    getData,
};

export default historyService;