import axios from "axios";

export interface UserInfo {
    id: number;
    coin: number;
    giftCoin: number;
}

const getData = async () => {
    const response = await axios.get<UserInfo>(`/v1/my`);
    return response.data;
};

const userService = {
    getData
};

export default userService;