import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { NavBar } from "antd-mobile";
import HistoryList from "./HistoryList";
import FavoriteList from "./FavoriteList";

interface LocationState {
    referer: string;
}

const History = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const back = () => navigate(-1);
    const [type, setType] = useState<number>(0);
  
    useEffect(() => {
        const locationState = location.state as LocationState;
        if( locationState.referer === 'history' ) {
            setType(2);
        }
        if( locationState.referer === 'favorite' ) {
            setType(1);
        }
    }, []);

    console.log(location);


    if( type === 0 ) {
        return null;
    }
    return (
        <>
            <NavBar onBack={back} style={{ "--border-bottom": "1px solid #eeeeee" }}>{type === 1 ? '我的收藏':'最近阅读'}</NavBar>
            <div className="grid grid-cols-2 gap-4 border-b border-slate-100 text-center text-lg px-2">
                <div>
                    <span className={`pt-2.5 pb-2 inline-block ${type === 1 ? 'text-orange-500 border-b-2 border-orange-500 ' : ''}`} onClick={() => { setType(1); }}>我的收藏</span>
                </div>
                <div>
                    <span className={`pt-2.5 pb-2 inline-block ${type === 2 ? 'text-orange-500 border-b-2 border-orange-500 ' : ''}`} onClick={() => { setType(2); }}>最近阅读</span>
                </div>
            </div>
            {type === 1 ?
                <FavoriteList />
                :
                <HistoryList />
            }
        </>
    );
};

export default History;