import axios from "axios";

export interface ConsumeItem {
    id: number;
    type: number;
    title: string;
    coin: number;
    giftCoin: number;
    date: string;
}

export interface ConsumeData {
    list: ConsumeItem[];
    nextCursor: number;
}

interface QueryParam {
    pageParam: number;
}

const getAll = async ({pageParam}: QueryParam) => {
    const response = await axios.get<ConsumeData>(`/v1/consume?page=${pageParam}`);
    return response.data;
};

const consumeService = {
    getAll,
};

export default consumeService;